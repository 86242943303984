.hp__chatbot {
    position: relative;
    width: 100dvw;
    left: 50%;
    padding: 10vw 1rem;
    transform: translateX(-50%);
    background: linear-gradient(180deg, var(--color--grey-xlight) 0%, var(--color--white) 100%);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-size: 0.875rem;
    font-weight: 500;
    border-top-left-radius: 20vw;
    margin-top: 10vw;

    .hp__chatbot__svg {
        position: absolute;
        top: -20vw;
        right: 0;
        width: 20vw;

        path {
            fill: var(--color--grey-xlight);
        }
    }

    .hp__chatbot__header {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1.5rem;

        .hp__chatbot__title {
            margin: 0;
            font-size: 1.5rem;
        }

        p {
            margin: 0;
        }

        i.icon {
            font-size: 3rem;
            color: var(--color--rain);
            background-color: var(--color--white);
            border-radius: 1rem;
            padding: 1rem;
            margin-bottom: 1.5rem;
        }
    }

    .hp__chatbot__container {
        display: grid;
        grid-template-rows: minmax(0, 1fr) minmax(0, 4.5rem);
        gap: 1rem;
        align-items: flex-end;
        border-radius: 0.5rem;
        background: var(--color--white);
        box-shadow: var(--shadow);
        height: 70vh;

        @media screen and (min-width: 1024px) {
            grid-template-rows: minmax(0, 1fr) minmax(0, 6rem);
        }
    }

    .hp__chatbot__response {
        display: flex;
        margin: 1rem;
        background-color: var(--color--grey-xlight);
        border-radius: 0.5rem;
        padding: 1rem;
        height: 100%;

        > div {
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 2.5rem);
            gap: 1.5rem;
            align-items: center;
            width: 100%;
        }
    }

    .hp__chatbot__button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5rem;
        height: 2.5rem;
        font-size: 0;
        border: none;
        border-radius: 50%;
        background-color: var(--color--rain);
        color: var(--color--white);
        cursor: pointer;

        &::before {
            content: "\e91c";
            font-family: 'whentoexplore' !important;
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 1rem;
        }
        
        &.hp__chatbot__button--prev {
            display: none;
            background-color: var(--color--grey-medium);
            
            &::before {
                position: relative;
                transform: rotate(180deg);
            }
        }
    }

    .hp__chatbot__messages {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        overflow-y: auto;
        padding: 1rem;
        max-height: 100%;

        .hp__chatbot__message {
            display: flex;
            align-items: flex-end;
            gap: 0.75rem;

            p {
                background: var(--color--grey-xlight);
                padding: 0.75rem 1rem;
                border-radius: 2rem;
                margin: 0;
            }
            
            p:empty {
                display: none;
            }
        }

        .hp__chatbot__message--bot {
            align-self: flex-start;

            p {
                border-radius: 1rem 1rem 1rem 0;
            }

            &::before {
                content: "\e9d0";
                font-family: 'whentoexplore' !important;
                speak: never;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                background: linear-gradient(145deg, var(--color--surf-75), var(--color--ski-75) 80%);
                color: var(--color--white);
                border-radius: 2rem;
                width: 2rem;
                min-width: 2rem;
                height: 2rem;
                font-size: 1.125rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .hp__chatbot__message--user {
            align-self: flex-end;

            p {
                background-color: var(--color--rain);
                color: var(--color--white);
                font-weight: 600;
                border-radius: 1rem 1rem 0 1rem;
            }

            &::before {
                order: 1;
                content: "\e96b";
                font-family: 'whentoexplore' !important;
                speak: never;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                background-color: var(--color--rain);
                color: var(--color--white);
                border-radius: 2rem;
                width: 2rem;
                min-width: 2rem;
                height: 2rem;
                font-size: 1.125rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .hp__chatbot__message--overwritten {
            text-decoration: line-through;
            opacity: 0.5;
        }

        @media screen and (min-width: 1024px) {
            padding: 2rem;
        }
    }

    .hp__chatbot__options {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        font-weight: 500;

        span {
            display: block;
            border: 0.0625rem solid var(--color--grey-light);
            padding: 0.375rem 0.5rem;
            border-radius: 0.5rem;
            cursor: pointer;
            background-color: var(--color--white);
        }

        input[type="checkbox"],
        input[type="radio"] {
            display: none;
        }

        input[type="checkbox"]:checked + span,
        input[type="radio"]:checked + span {
            background-color: var(--color--rain);
            color: var(--color--white);
        }

        @media screen and (max-width: 1023px) {
            flex-wrap: nowrap;
            overflow: auto;
            white-space: nowrap;
        }
    }

    @media screen and (min-width: 1024px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 10vw 6rem;
    }
}

.hp__chatbot__results a {
    color: #007bff;
    text-decoration: none;
}

.hp__chatbot__results a:hover {
    text-decoration: underline;
    color: #0056b3;
}
